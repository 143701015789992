/* media Queries for banner right side image*/
@media (max-width: 1129px) and (min-width: 1023px) {
  .homepage-img {
    height: 650px;
  }
}

/* media Queries for footer content*/
@media (max-width: 500px) and (min-width: 320px) {
  .mobile-width {
    width: 50% !important;
  }
}
@media (max-width: 1199px) and (min-width: 320px) {
  .banner_img {
    background-size: contain !important;
  }
}
