@import url("./media.css");
@import url("./homepage.css");
body {
  margin: 0;
  padding: 0;
}
.banner_img {
  background-image: url("../assets/img/banner-new.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/* fonts  */
.font-dm {
  font-family: "DM Sans", sans-serif;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}
.video_img {
  background-image: url("../assets/img/video-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.metaverse_img {
  background-image: url("../assets/img/metaverse.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1023px) and (min-width: 320px) {
  .metaverse_img {
    background-color: #edf3f6;
    background-image: none !important;
    border-radius: 30px;
  }
}

/* font - MelodySunday */
@font-face {
  font-family: "MelodySunday";
  src: url("../assets/fonts/MelodySunday.otf");
}

.font-MelodySunday {
  font-family: "MelodySunday";
}
/* banner video container */
.video-container {
  width: 100% !important;
  height: 100% !important;
}